// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'
import firebase from 'firebase'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    route('HomePage', null, '/'),
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/loginpage',
      name: 'LoginPage',
      component: () => import('@/views/LoginPage'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/tourdetail/:itemId',
      name: 'TourDetail',
      props: true,
      component: () => import('@/views/tours/TourDetail'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/tripdetail/:tripId',
      name: 'TripDetail',
      props: true,
      component: () => import('@/views/tours/TripDetail'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/mytrips/',
      name: 'MyTrips',
      props: true,
      component: () => import('@/views/tours/MyTrips'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/triplocations/',
      name: 'TripLocations',
      props: true,
      component: () => import('@/views/tours/TripLocations'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/locationdetail/:locationId',
      name: 'TripLocationDetail',
      props: true,
      component: () => import('@/views/tours/TripLocationDetail'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/profile/',
      name: 'UserProfile',
      props: true,
      component: () => import('@/views/tours/UserProfile'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/restaurants',
      name: 'Restaurants',
      props: true,
      component: () => import('@/views/tours/Restaurants'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/aimguud',
      name: 'Aimguud',
      props: true,
      component: () => import('@/views/tours/Aimguud'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/adminaimguud',
      name: 'AdminAimguud',
      props: true,
      component: () => import('@/views/admin/AdminAimguud'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/shopping',
      name: 'Shopping',
      props: true,
      component: () => import('@/views/shop/Shopping'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/restaurants/:restaurantId',
      name: 'RestaurantDetail',
      props: true,
      component: () => import('@/views/tours/RestaurantDetail'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/aboutus',
      name: 'Aboutus',
      component: () => import('@/views/pages/Aboutus'),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: '/imageadd/:objectPath',
      name: 'ImageAdd',
      props: true,
      component: () => import('@/components/ImageAdd'),
      meta: {
        requiresAuth: false,
      },
    },
 
    layout('Default', [
      // Pages
      route('UserProfile', null, 'components/profile'),
      route('MySetting', null, 'components/mysetting'),

      // Components
      route('Dashboard', null, 'dashboard'),

      // Tables
      route('Regular Tables', null, 'tables/regular'),
      route('Orders', null, 'tables/orders'),
      // Maps
      route('Google Maps', null, 'maps/google'),
      {
        path: '/coursedetail/:courseId',
        name: 'CourseDetail',
        props: true,
        component: () => import('@/views/courses/CourseDetail'),
        // children: [
        //     {
        //         path: 'qresponses',
        //         name: 'Qresponses',
        //         props: true,
        //         component: () => import('../views/MySetting'),
        //     },
        //     {
        //       path: 'qquestionediting',
        //       name: 'QEditing',
        //       props: true,
        //       component: () => import('../views/question/QuestionEditing'),
        //     },
        //     {
        //       path: 'zparticipants',
        //       name: 'ZParticipants',
        //       props: true,
        //       component: () => import('@/views/question/ZParticipants'),
        //     },
        //     {
        //       path: 'zresponses',
        //       name: 'ZResponses',
        //       props: true,
        //       component: () => import('@/views/question/ZResponses'),
        //     },
        // ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/qdetail/:asuulgaId',
        name: 'QDetail',
        props: true,
        component: () => import('@/views/QDetail'),
        children: [
          {
            path: 'qresponses',
            name: 'Qresponses',
            props: true,
            component: () => import('../views/MySetting'),
          },
          // {
          //   path: 'qquestionediting',
          //   name: 'QEditing',
          //   props: true,
          //   component: () => import('../views/question/QuestionEditing'),
          // },


        ],
        meta: {
          requiresAuth: true,
        },
      },




    ]),
  ],
})

// router.beforeEach((to, from, next) => {
//   return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
// })

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else if (requiresAuth === false) {
    next()
  } else { next('/login') }
})
export default router
