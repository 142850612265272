// Pathify
import { make } from 'vuex-pathify'

const state = {
  cities: [
    {
      name: "Улаанбаатар",
      sumuud: [
        "Хан-Уул",
        "Баянзүрх",
        "Чингэлтэй",
        "Налайх",
        "Сонгинохайрхан",
        "Баянгол",
        "Багахангай",
        "Сүхбаатар",
      ],
    },
    {
      name: "Архангай",
      sumuud: [
        "Ихтамир",
        "Өлзийт",
        "Хайрхан",
        "Эрдэнэмандал",
        "Цэнхэр",
        "Төвшрүүлэх",
        "Булган",
        "Цахир",
        "Тариат",
        "Батцэнгэл",
        "Цэцэрлэг",
        "Хангай",
        "Хашаат",
        "Чулуут",
        "Хотонт",
        "Цэцэрлэг",
        "Өгийнуур",
        "Өндөр-Улаан",
        "Жаргалант",
      ],
    },
    {
      name: "Баян-Өлгий",
      sumuud: [
        "Булган",
        "Толбо",
        "Буянт",
        "Сагсай",
        "Баяннуур",
        "Цагааннуур",
        "Цэнгэл",
        "Бугат",
        "Дэлүүн",
        "Алтай",
        "Улаанхус",
        "Ногооннуур",
        "Алтанцөгц",
      ],
    },
    {
      name: "Баянхонгор",
      sumuud: [
        "Баян-Овоо",
        "Шинэжинст",
        "Галуут",
        "Баянговь",
        "Бөмбөгөр",
        "Баянлиг",
        "Өлзийт",
        "Баянхонгор",
        "Хүрээмарал",
        "Бууцагаан",
        "Эрдэнэцогт",
        "Баянцагаан",
        "Богд",
        "Баянбулаг",
        "Жаргалант",
        "Жинст",
        "Баацагаан",
        "Баян-Өндөр",
        "Заг",
        "Гурванбулаг",
      ],
    },
    {
      name: "Булган",
      sumuud: [
        "Дашинчилэн",
        "Рашаант",
        "Могод",
        "Хутаг-Өндөр",
        "Сайхан",
        "Сэлэнгэ",
        "Бүрэгхангай",
        "Баян-Агт",
        "Тэшиг",
        "Булган",
        "Гурванбулаг",
        "Орхон",
        "Бугат",
        "Баяннуур",
        "Хангал",
        "Хишиг-Өндөр",
      ],
    },
    {
      name: "Говь-Алтай",
      sumuud: [
        "Sharga",
        "Tsogt",
        "Biger",
        "Tseel",
        "Bayan-Uul",
        "Bugat",
        "Tonkhil",
        "Delger",
        "Khaliun",
        "Tögrög",
        "Erdene",
        "Taishir",
        "Darvi",
        "Jargalan",
        "Chandmani",
        "Yesönbulag",
      ],
    },
    {
      name: "Говьсүмбэр",
      sumuud: ["Сүмбэр", "Баянтал сум", "Шивээговь сум"],
    },
    {
      name: "Дархан-Уул",
      sumuud: ["Дархан", "Хонгор", "Орхон", "Шарынгол"],
    },
    {
      name: "Дорноговь",
      sumuud: [
        "Замын-Үүд",
        "Хатанбулаг",
        "Алтанширээ",
        "Дэлгэрэх",
        "Даланжаргалан",
        "Иххэт",
        "Цахимхолбоо",
        "Улаанбадрах",
        "Мандах",
        "Сайхандулаан",
        "Эрдэнэ",
        "Сайншанд",
        "Өргөн",
        "Хөвсгөл",
        "Айраг",
      ],
    },
    {
      name: "Дорнод",
      sumuud: [
        "Булган",
        "Хөлөнбуйр",
        "Сэргэлэн",
        "Чойбалсан",
        "Баянтүмэн",
        "Хэрлэн*",
        "Баян-Уул",
        "Матад",
        "Чулуунхороот",
        "Баяндун",
        "Цагаан-Овоо",
        "Гурванзагал",
        "Дашбалбар",
        "Халхгол",
      ],
    },
    {
      name: "Дундговь",
      sumuud: [
        "Адаацаг",
        "Сайнцагаан",
        "Баянжаргалан",
        "Гурвансайхан",
        "Сайхан-Овоо",
        "Цагаандэлгэр",
        "Өлзийт",
        "Дэрэн",
        "Дэлгэрцогт",
        "Хулд",
        "Өндөршил",
        "Луус",
        "Дэлгэрхангай",
        "Говь-Угтаал",
        "Эрдэнэдалай",
      ],
    },
    {
      name: "Завхан",
      sumuud: [
        "Алдархаан",
        "Тэлмэн",
        "Асгат",
        "Дөрвөлжин",
        "Завханмандал",
        "Сонгино",
        "Идэр",
        "Отгон",
        "Тосонцэнгэл",
        "Баянтэс",
        "Нөмрөг",
        "Тэс",
        "Шилүүстэй",
        "Их-Уул",
        "Түдэвтэй",
        "Цагаанхайрхан",
        "Эрдэнэхайрхан",
        "Яруу",
        "Улиастай",
        "Баянхайрхан",
        "Цэцэн-Уул",
        "Сантмаргац",
        "Ургамал",
        "Цагаанчулуут",
      ],
    },
    { name: "Орхон", sumuud: ["Баян-Өндөр", "Жаргалант"] },
    {
      name: "Өвөрхангай",
      sumuud: [
        "Баруунбаян-Улаан",
        "Уянга",
        "Богд",
        "Зүүнбаян-Улаан",
        "Бүрд",
        "Сант",
        "Нарийнтээл",
        "Хайрхандулаан",
        "Арвайхээр",
        "Есөнзүйл",
        "Хужирт",
        "Өлзийт",
        "Гучин-Ус",
        "Төгрөг",
        "Тарагт",
        "Хархорин",
        "Баянгол",
        "Бат-Өлзий",
        "Баян-Өндөр",
      ],
    },
    {
      name: "Өмнөговь",
      sumuud: [
        "Манлай",
        "Сэврэй",
        "Цогтцэций",
        "Хүрмэн",
        "Цогт-Овоо",
        "Гурвантэс",
        "Номгон",
        "Ханхонгор",
        "Мандал-Овоо",
        "Даланзадгад",
        "Ноён",
        "Баян-Овоо",
        "Баяндалай",
        "Ханбогд",
        "Булган",
      ],
    },
    {
      name: "Сүхбаатар",
      sumuud: [
        "Сүхбаатар",
        "Мөнххаан",
        "Халзан",
        "Баруун-Урт",
        "Эрдэнэцагаан",
        "Түвшинширээ",
        "Түмэнцогт",
        "Баяндэлгэр",
        "Наран",
        "Асгат",
        "Дарьганга",
        "Онгон",
        "Уулбаян",
      ],
    },
    {
      name: "Сэлэнгэ",
      sumuud: [
        "Сайхан",
        "Сүхбаатар",
        "Шаамар",
        "Орхон",
        "Жавхлант",
        "Цагааннуур",
        "Баруунбүрэн",
        "Зүүнбүрэн",
        "Алтанбулаг",
        "Хушаат",
        "Түшиг",
        "Хүдэр",
        "Орхонтуул",
        "Сант",
        "Баянгол",
        "Мандал",
        "Ерөө",
      ],
    },
    {
      name: "Төв",
      sumuud: [
        "Дэлгэрхаан",
        "Борнуур",
        "Эрдэнэ",
        "Угтаалцайдам",
        "Жаргалант",
        "Баянчандмань",
        "Архуст",
        "Баянцогт",
        "Баян",
        "Өндөрширээт",
        "Цээл",
        "Сэргэлэн",
        "Сүмбэр",
        "Мөнгөнморьт",
        "Эрдэнэсант",
        "Баян-Өнжүүл",
        "Батсүмбэр",
        "Баянжаргалан",
        "Аргалант",
        "Бүрэн",
        "Баянцагаан",
        "Баянхангай",
        "Лүн",
        "Заамар",
        "Зуунмод",
        "Алтанбулаг",
        "Баяндэлгэр",
      ],
    },
    {
      name: "Увс",
      sumuud: [
        "Өлгий",
        "Завхан",
        "Бөхмөрөн",
        "Малчин",
        "Хяргас",
        "Зүүнхангай",
        "Тэс",
        "Давст",
        "Наранбулаг",
        "Улаангом",
        "Сагил",
        "Зүүнговь",
        "Тариалан",
        "Түргэн",
        "Цагаанхайрхан",
        "Ховд",
        "Өндөрхангай",
        "Өмнөговь",
        "Баруунтуруун",
      ],
    },
    {
      name: "Ховд",
      sumuud: [
        "Дуут",
        "Дөргөн",
        "Чандмань",
        "Алтай",
        "Зэрэг",
        "Мөст",
        "Мөнххайрхан",
        "Манхан",
        "Жаргалант",
        "Дарви",
        "Ховд",
        "Булган",
        "Үенч",
        "Эрдэнэбүрэн",
        "Цэцэг",
        "Мянгад",
        "Буянт",
      ],
    },
    {
      name: "Хөвсгөл",
      sumuud: [
        "Цэцэрлэг",
        "Рэнчинлхүмбэ",
        "Тосонцэнгэл",
        "Алаг-Эрдэнэ",
        "Рашаант",
        "Улаан-Уул",
        "Мөрөн",
        "Түнэл",
        "Галт",
        "Ханх",
        "Цагаан-Үүр",
        "Тариалан",
        "Цагааннуур",
        "Бүрэнтогтох",
        "Арбулаг",
        "Чандмань-Өндөр",
        "Баянзүрх",
        "Эрдэнэбулган",
        "Цагаан-Уул",
        "Төмөрбулаг",
        "Хатгал",
        "Их-Уул",
        "Жаргалант",
        "Шинэ-Идэр",
      ],
    },
    {
      name: "Хэнтий",
      sumuud: [
        "Баян-Адрага",
        "Дэлгэрхаан",
        "Дархан",
        "Өмнөдэлгэр",
        "Мөрөн",
        "Галшар",
        "Батноров",
        "Баянхутаг",
        "Хэрлэн",
        "Баянмөнх",
        "Дадал",
        "Батширээт",
        "Жаргалтхаан",
        "Өлзийт",
        "Норовлин",
        "Цэнхэрмандал",
        "Биндэр",
        "Баян-Овоо",
      ],
    },
  ],
}

const mutations = make.mutations(state)
const actions = {}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
