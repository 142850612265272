// Vue
import Vue from 'vue'
import Vuex from 'vuex'

import pathify from '@/plugins/vuex-pathify'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const fb = require('../firebaseConfig.js')
fb.auth.onAuthStateChanged(user => {
    if (user) {
        fb.currentUser = user
        store.commit('setCurrentUser', user)
        store.dispatch('fetchUserProfile')
    }
})

const store = new Vuex.Store({
    currentUser: null,
    userProfile: {},
    modules,
    plugins: [
        pathify.plugin,
    ],

    actions: {
        clearData({ commit }) {
            console.log('clear dataa.....')
            commit('setCurrentUser', null)
            commit('setUserProfile', {})
        },
        fetchUserProfile({ commit, state }) {
            fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
                if (res.exists) { commit('setUserProfile', res.data()) } else {
                    // fb.usersCollection.where("authID", "==", state.currentUser.uid).limit(1).get().then(res => {
                    //     if(res.docs!=null && res.docs.length>0){
                    //         var tmp=res.docs[0].data();
                    //         tmp.id=res.docs[0].id;
                    //         commit('setUserProfile',tmp);
                    //     }
                    //     else{

                    //     }
                    // });
                }
            }).catch(err => {
                console.log(err)
            })
        },
    },
    mutations: {
        setCurrentUser(state, val) {
            state.currentUser = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
        },

    },
    getters: {
        currentUser: state => {
            return state.currentUser
        },
    },
})

store.subscribe(mutation => {
    if (!mutation.type.startsWith('user/')) return

    store.dispatch('user/update', mutation)
})

store.dispatch('app/init')

export default store

export const ROOT_DISPATCH = Object.freeze({ root: true })