import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import firebase from 'firebase'
import 'firebase/firestore'
import VuetifyConfirm from 'vuetify-confirm'
import VueCookies from 'vue-cookies'
import { firestorePlugin } from 'vuefire'
import Vue2MapboxGL from 'vue2mapbox-gl';

// You might want to import the relevant css, for example:
import 'mapbox-gl/dist/mapbox-gl.css';
// for the v-mapbox-geocoder
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        

const fb = require('./firebaseConfig.js')

Vue.config.productionTip = false

Vue.use(firestorePlugin)
Vue.use(require('vue-moment'))
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueCookies)
Vue.use(Vue2MapboxGL);

import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);


sync(store, router)

firebase.auth().onAuthStateChanged(user => {
    store.dispatch('false')
    if (user) {
        fb.usersCollection.doc(user.uid).get().then(userData => {
            new Vue({
                router,
                vuetify,
                store,
                render: h => h(App),
            }).$mount('#app')
        })
        store.commit('setCurrentUser', user)
        store.dispatch('fetchUserProfile')
    } else {
        store.dispatch('clearData')
        new Vue({
            router,
            vuetify,
            store,
            render: h => h(App),
        }).$mount('#app')
    }
})

Vue.$cookies.config('7d')
    // Vue.config.productionTip = false