// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {

    drawer: null,
    drawerImage: true,
    mini: false,
    items: [{
            title: 'Dashboard',
            icon: 'mdi-view-dashboard',
            to: '/dashboard',
        },
        // {
        //   title: 'User Profile',
        //   icon: 'mdi-account',
        //   to: '/components/profile/',
        // },
        {
            title: 'Миний сургалтууд',
            icon: 'mdi-clipboard-outline',
            to: '/tables/regular/',
        },
        {
            title: 'Xудалдсаж авсан',
            icon: 'mdi-clipboard-outline',
            to: '/tables/orders/',
        },
    ],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    init: async({ dispatch }) => {
        //
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}