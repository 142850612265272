<template>
  <v-app>
    <v-container>
      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          v-if="object"
          md="6" 
          offset-md="1"
        >
          <v-card
            flat
            color="transparent"
            style="margin-top: 20vh;"
          >
            <v-row
              v-if="object.images != null && object.images.length > 0"
              class="justify-center"
            >
              <v-img 
                :src="object.images[0].fileUrl"
                min-width="100%"
                @click="deleteFile(object.images[0], 0)"
              />
            </v-row>
            <v-row
              v-else
              class="justify-center"
            >
              <p class="error--text">
                Одоогоор зураг ороогүй байна. Зургаа сонгоно уу!
              </p>
            </v-row>
            <v-card-actions class="justify-center">
              <input
                v-if="object.images!=null&& object.images!=undefined&&object.images.length==0"
                ref="file"
                class="drop"
                type="file"
                @change="uploadFile1"
              >
              <br>
              <v-btn
                v-if="object.images!=null&& object.images!=undefined&&object.images.length>0"
                class="mt-10 px-10 white--text"
                color="secondary"
                @click="deleteFile(object.images[0], 0)"
              >
                delete
              </v-btn>
              <v-btn
                class="ml-10 mt-10 px-10 white--text"
                color="green"
                @click="$router.back()"
              >
                <span v-if="object.images.length==0"> Буцаx</span>
                <span v-else>Xадгалаx</span>
              </v-btn>
              <div
                v-if="uploading"
                class="progress"
              >
                <div
                  v-if="uploadPercent<100 && uploadPercent>0"
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: uploadPercent + '%' }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ uploadPercent.toFixed() }}%
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  const fb = require("../firebaseConfig.js")
  export default {
    name: "ImageAdd",
    props: {
      objectPath: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        object: null,

        uploading: false,
        uploadPercent: null,
      }
    },
    created() {
      if (this.objectPath != null && this.objectPath !== "") {
        this._getTour()
      }
    },
    methods: {
      async _getTour() {
        var tourData = await fb.db.doc(this.objectPath).get()
        const object = tourData.data()
        object.id = tourData.id
        this.object = object
      },
      uploadFile1() {
        var _this = this
        var files = this.$refs.file.files

        if (files != null && files.length > 0) {
          var file = files[0]
          var now = new Date()
          var fileName = +now.getTime() + "_" + file.name
          var uploadTask = fb.storageRef.child(fileName).put(file)
          _this.uploading = true
          _this.uploadPercent = 0
          uploadTask.on(
            fb.storage.TaskEvent.STATE_CHANGED,
            function (snapshot) {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              _this.uploadPercent = progress
            },
            function (error) {
              switch (error.code) {
                case "storage/unauthorized":
                  break

                case "storage/canceled":
                  break

                case "storage/unknown":
                  break
              }
            },
            function () {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  const fileObject = {
                    fileName: fileName,
                    fileUrl: downloadURL,
                    uploadedAt: now,
                  }
                  fb.db.doc(_this.objectPath).update({
                    images: fb.firestore.FieldValue.arrayUnion(fileObject),
                  })

                  if (_this.object != null) {
                    if (_this.object.images == null) _this.object.images = []
                    _this.object.images.push(fileObject)
                  }
                  // eslint-disable-next-line no-sequences
                  // (_this.uploading === false), (_this.uploadPercent === null)

                  _this.$refs.file.value = ""

                  if (!/safari/i.test(navigator.userAgent)) {
                    _this.$refs.file.type = ""
                    _this.$refs.file.type = "file"
                  }
                })
            },
          )
        }
      },
      deleteFile(file, index) {
        var _this = this
        var result = confirm("Та " + file.fileName + " файлыг устгах уу?")
        if (result) {
          var fileRef = fb.storageRef.child(file.fileName)
          fileRef
            .delete()
            .then(() => {
              this.object.images.splice(index, 1)
              fb.db.doc(this.objectPath).update({
                images: this.object.images,
              })
              _this.uploadPercent = 0
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      },
    },
  }
</script>
<style>
  .drop{
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  display: block;
}
.excel-upload-input{
  display: none;
  z-index: -9999;
}
</style>
