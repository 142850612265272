<template>
  <v-card
    class="mx-auto"
    color="white"
    max-width="300"
    @click="_goDetail"
  >
    <!-- :style="{'background-color': asuulga.bgColor?asuulga.bgColor:'yellow','color': asuulga.textcolor?asuulga.textcolor:'white'}" -->
    <v-card-title>
      <span class="title font-weight-light">xxxxx</span>
    </v-card-title>
  </v-card>
</template>

<script>
  export default {
    name: 'Casd',
  }
  </script>
