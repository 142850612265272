<template>
  <v-app>
    <header>
      <v-app-bar fixed class="shadow" color="transparent" flat dark>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="navbtn"
        ></v-app-bar-nav-icon>

        <v-toolbar-title
          ><h1 class="display-2" @click="$router.push('/')" style="cursor:pointer;font-weight: bold">
            Mongolia365
          </h1></v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-tabs v-model="topMenu" style="max-width: 600px" right color="white" class="navmenu">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(item,iindex) in topmenuitems"
            :key="iindex"
            class="px-7 white--text"
            style="font-weight: bold"
            :to="item.href"
            @click="rename(item.href)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <v-btn
          dark
          class="ml-7"
          color="success"
          style="color: white!important"
          @click="_login0"
          >Log In</v-btn
        >

        <template v-slot:extension>
          <v-tabs centered color="white" dark optional class="navmenu">
            <v-tab
              v-for="(item,iindex) in items1"
              :key="iindex"
              :href="item.href"
              class="px-7 display-1 white--text"
              style="font-weight: bold"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </header>

    <v-navigation-drawer absolute temporary v-model="drawer" dark>
      <v-list shaped>
        <v-subheader class="text-h4">MENU</v-subheader>
        <v-list-item-group v-model="selectedItem" color="red">
          <v-list-item v-for="(item, i) in items1" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader class="text-h4">Others</v-subheader>
          <v-list-item v-for="(item, i) in topmenuitems" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="background-color: #fff">
      <router-view />
    </v-main>
    <v-footer color="white">
      <v-container>
        <v-card flat tile>
          <v-card-title>
            <strong class="app-subtitle black--text"
              >Монголын сайxан нутгаар - Mongolia <span class="font-weight-bold">365</span></strong
            >

            <v-spacer></v-spacer>

            <v-btn v-for="icon in socials" :key="icon" dark icon>
              <v-icon size="24px" color="#f7775e">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>

            <!-- <v-text-field dense tile outlined></v-text-field>
              <v-btn
                color="#000"
                dark
                tile
                class="px-6 my-2 btn-text"
                @click="_goDetail"
                >Дэлгэрэнгүй</v-btn
              > -->
          </v-card-title>

          <v-card-text class="py-2 app-subtitle text-center">
            {{ new Date().getFullYear() }} — <strong>Through Mongolia</strong>
          </v-card-text>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// Styles
import "@/styles/overrides.sass";

export default {
  name: "App",
  data: () => ({
    selectedItem:null,
    drawer:null,
    topMenu: "/",
    topmenuitems: [
      { name: "Home", href: "/" },
      { name: "Аялал оруулаx", href: "/mytrips" },
      { name: "Газар оруулаx", href: "/triplocations" },
      { name: "Blog оруулаx", href: "/addblog" },
    ],
    items1: [
      { name: "Аялалууд", href: "/mytrips" },
      // { name: "Аймгууд" },
      { name: "Монгол зоог", href: "/restaurants" },
      { name: "Аймгууд", href: "/aimguud" },
      // { name: "Shopping", href: "/shopping" },
    ],
    socials: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),

  metaInfo: {
    title: "Mongolia365: Travel recommendation system",
    titleTemplate: "%s | Traveling system",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  methods: {
    _login0() {
      this.$router.push({ name: "LoginPage" });
    },
    rename(href) {
      this.topMenu = href
    }
  },
};
</script>
<style>
.description{
   color: #8d97ad !important
}
</style>