import firebase from 'firebase'
import 'firebase/firestore'
// a1lci65L9fsS5RD6iWl6

const config = {
    // apiKey: 'AIzaSyBdvvY1gFHi7UEAAJkRkPz4xUbBXQfUdOw',
    // authDomain: 'asuulga-mat.firebaseapp.com',
    // projectId: 'asuulga-mat',
    // storageBucket: 'asuulga-mat.appspot.com',
    // messagingSenderId: '10169985572',
    // appId: '1:10169985572:web:f5e4b24bf812e04d61c6b1',
    // measurementId: 'G-J66M1TWYY6',

    // apiKey: "AIzaSyDMNkJOiw2xAPLTKuJiqOV-6Wjnr4BPE5U",
    // authDomain: "gradeduhomepage.firebaseapp.com",
    // projectId: "gradeduhomepage",
    // storageBucket: "gradeduhomepage.appspot.com",
    // messagingSenderId: "645525186555",
    // appId: "1:645525186555:web:76888cb1d13a78d481cd5e",
    // measurementId: "G-794BP6CD1Q"

    // apiKey: "AIzaSyB6_r29cG_YcwnTBSzDUEyy_RYmE0xAc88",
    // authDomain: "xtravel-fa672.firebaseapp.com",
    // projectId: "xtravel-fa672",
    // storageBucket: "xtravel-fa672.appspot.com",
    // messagingSenderId: "903621113652",
    // appId: "1:903621113652:web:dc7ddf593f87e2a7015761"

    apiKey: "AIzaSyBeZT5Li1vKp_w3lcsu06S2qjMfiw1Ru84",
    authDomain: "mongolia365-287f0.firebaseapp.com",
    projectId: "mongolia365-287f0",
    storageBucket: "mongolia365-287f0.appspot.com",
    messagingSenderId: "540359963359",
    appId: "1:540359963359:web:c1507a0baaf639f1fbaf6a",
    measurementId: "G-0VZKGXT8WS"
}



const firestore = firebase.firestore
const db = firebase.initializeApp(config).firestore();
const auth = firebase.auth()
const currentUser = auth.currentUser
var googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('email')
const usersCollection = db.collection('users')
const ratingCollection = db.collection('restaurant')
const storage = firebase.storage
const storageRef = firebase.storage().ref()

const updateRating = (id, val) => {
    return ratingCollection.doc(id).update(val)
}
export {
    db,
    auth,
    currentUser,
    googleProvider,
    ratingCollection,
    usersCollection,
    updateRating,
    storage,
    storageRef,
    firestore,
}